import { Route, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CrudService {

    private apiUrl = `${environment.API_SERVER}/`;
    private apiNotification = `${environment.API_NOTIFICATION}/`;
    private apiTileUrl = `${environment.API_TILE}`;
    private apiStatictis = `${environment.API_STATISTIC}`;
    private apiTeltonika = `${environment.API_TELTONIKA}`;
    private apiIvTramas = `${environment.IVTRAMAS}`;
    private apiTramas = `${environment.API_TRAMAS}`;
    private apiGeo = `${environment.API_GEO}`;
    private apiLogin = `${environment.API_URL}api-login`;
    public token;
    public authenticationHeaders;
    public authenticationHeadersStatistics;
    
    constructor(private http: HttpClient, private router: Router) {
      if(this.router.url != '/'){
        this.authenticationHeaders = new HttpHeaders().set('x-access-token', this.getToken())
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        this.authenticationHeadersStatistics = new HttpHeaders().set('Authorization', this.getToken());
      }
    }

    initHeaders(){
      this.authenticationHeaders = new HttpHeaders().set('x-access-token', this.getToken())
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
      this.authenticationHeadersStatistics = new HttpHeaders().set('Authorization', this.getToken());
    }
    getToken() {
        let token = localStorage.getItem('id_token');
        (token != 'undefined') ? this.token = token : this.token = null;
        return this.token;
    }


    public getUrlATSSERVER(){
      return this.apiUrl
    }
    public get<T>(entityName: string, params?): Promise<any> {
        return this.http.get<any[]>(this.apiUrl + entityName, { headers: this.authenticationHeaders, params: params }).toPromise();
    }

    public getQueryParams<T>(): Promise<any> {
        return this.http.get<any[]>(this.apiUrl, { withCredentials: true }).toPromise();
    }

    public getEntities<T>(entityName: string, params?): Promise<any> {
        return this.http.get<any[]>(this.apiUrl + entityName + "/getAll", { headers: this.authenticationHeaders, params }).toPromise();
    }

    public getEntity<T>(entityName: string, id: any): Observable<any> {
        return this.http.get<any>(this.apiUrl + entityName + "/get/" + id, { headers: this.authenticationHeaders, });
    }

    public getLogin<T>(id: any): Observable<any> {
      return this.http.get<any>(this.apiLogin + "/get-user/" + id, { headers: this.authenticationHeaders});
    }

    public getLoginUser<T>() : Observable<any> {
      return this.http.get<any>(this.apiLogin + "/get-All", { headers: this.authenticationHeaders});
    }

    public getManyEntity<T>(entityName: string, field: string, value: string): Observable<T[]> {
        return this.http.get<T[]>(this.apiUrl + entityName + "/getMany/" + field + "/" + value, { headers: this.authenticationHeaders, });
    }

    public getStatistic<T>(url: string, entity:T): Observable<any> {
      return this.http.post<any>(this.apiStatictis+url, entity,{ headers: this.authenticationHeadersStatistics});
    }

    public getIvtrama<T>(url:string, idMac: string): Observable<any> {
      return this.http.get<any>(this.apiIvTramas + url + '/getLast/mac/' + idMac, { headers: this.authenticationHeaders});
    }

    public countEntities<T>(entityName: string): Observable<T[]> {
        return this.http.get<T[]>(this.apiUrl + entityName + "/count/", { headers: this.authenticationHeaders, });
    }

    public post<T>(entityName: string, body:any, params?): Promise<any> {
      return this.http.post<any[]>(this.apiUrl + entityName, body, { headers: this.authenticationHeaders, params: params }).toPromise();
  }

    public existsEntity<T>(entityName: string, id: string): Observable<T[]> {
        return this.http.post<T[]>(this.apiUrl + entityName + "/exists/", id, { headers: this.authenticationHeaders, });
    }

    public createEntity<T>(entityName: string, entity: T) {
        return this.http.post<any>(this.apiUrl + entityName + "/create", entity, { headers: this.authenticationHeaders, });
    }

    public createNotification<T>(entityName: string, entity: T) {
      return this.http.post<any>(this.apiNotification + entityName + "/create", entity, { headers: this.authenticationHeaders, });
  }

    public createLogin<T>(data:any){
        return this.http.post<any>(this.apiLogin + "/create", data, { headers: this.authenticationHeaders});
    }

    public setDoutSimple<T>(entity: T){
      return this.http.post<any>(this.apiTeltonika+ 'commandController/setDoutSimple', entity, { headers: this.authenticationHeaders});
    }

    public createMany<T>(entityName: string, entity: T) {
      return this.http.post<any>(this.apiUrl + entityName + "/createMany", entity, { headers: this.authenticationHeaders, });
  }

  public put<T>(entityName: string, entity: T): Observable<any>{
    return this.http.put<T[]>(this.apiUrl + entityName, entity, { headers: this.authenticationHeaders});
  }

    public updateEntity<T>(entityName: string, entity: T): Observable<any> {
        return this.http.put<T[]>(this.apiUrl + entityName + "/update/", entity, { headers: this.authenticationHeaders, });
    }

    public updateLogin<T>(entity: T): Observable<any> {
      return this.http.put<T[]>(this.apiLogin + "/update/", entity, { headers: this.authenticationHeaders});
    }

    public updateMany<T>(entityName: string, entity: T): Observable<any>{
      return this.http.put<T[]>(this.apiUrl + entityName + '/updateMany', entity, { headers: this.authenticationHeaders});
    }

    public updateEntityWithId<T>(entityName: string, entity: T, entityId): Observable<any> {
        return this.http.put<T[]>(this.apiUrl + entityName + "/update/" + entityId, entity, { headers: this.authenticationHeaders, });
    }

    public updateEntities<T>(entityName: string, entities: T): Observable<any> {
        return this.http.put<T[]>(this.apiUrl + entityName + "/update/", entities, { headers: this.authenticationHeaders, });
    }

    public lastPosition<T>(entityName: string, entity: T ,entityId): Observable<any>{
      return this.http.put<T[]>(this.apiTileUrl + '/lastPosition/'+ entityName + entityId, entity, {headers: this.authenticationHeaders});
    }

    public getlastPosition<T>(): Observable<any>{
      return this.http.get<T[]>(this.apiTileUrl + '/lastPosition/multipleUsers', {headers: this.authenticationHeaders});
    }

    public lastPositionMany<T>(entityName: string, entity: T): Observable<any>{
      return this.http.put<T[]>(this.apiTileUrl + '/lastPosition/'+ entityName , entity, {headers: this.authenticationHeaders});
    }

    public deleteEntityWithBody<T>(entityName: string, entityId: {}): Observable<any> {
        return this.http.request<T[]>('delete', this.apiUrl + entityName + "/delete", { body: entityId, headers: this.authenticationHeaders, });
    }

    public lastPositionItem<T>(entityName: string, entity: T ,entityId): Observable<any>{
      return this.http.put<T[]>(this.apiTileUrl + '/lastPositionItem/'+ entityName + entityId, entity, {headers: this.authenticationHeaders});
    }

    public lastPositionItemMany<T>(entityName: string, entity: T): Observable<any>{
      return this.http.put<T[]>(this.apiTileUrl + '/lastPositionItem/'+ entityName , entity, {headers: this.authenticationHeaders});
    }

    public getGlobalCounts<T>(body?:string, params?): Promise<any>{
      return this.http.post<any[]>(this.apiGeo + '/count/getGlobalCounts/', body, { headers: this.authenticationHeaders, params: params }).toPromise();
    }

    public getTrajectories<T>(body?:string, params?): Promise<any>{
      return this.http.post<any[]>(this.apiTileUrl + '/trajectory/getTrajectories/', body, { headers: this.authenticationHeaders, params: params }).toPromise();
    }

    public getTrajectoriesTimeSegment<T>(body?:string, params?): Promise<any>{
      return this.http.post<any[]>(this.apiTileUrl + '/trajectory/getAllActiveTrajectory', body, { headers: this.authenticationHeaders, params: params }).toPromise();
    }

    public deleteEntityMongo<T>(id: string): Observable<any> {
      const options = {
        headers: this.authenticationHeaders,
        body: {
          idPersona: id
        }
      };
      return this.http.delete<T[]>(this.apiUrl + 'person/delete', options);
    }

    public deleteEntity<T>(entityName: string, id: string): Observable<any> {
        return this.http.delete<T[]>(this.apiUrl + entityName + "/delete/" + id, { headers: this.authenticationHeaders});
    }

    public deleteLogin<T>(id: string): Observable<any> {
        return this.http.delete<T[]>(this.apiLogin + "/delete/" + id, { headers: this.authenticationHeaders});
    }

    public deleteEntities<T>(entityName: string, field: string, value: string): Observable<any> {
        return this.http.delete<T[]>(this.apiUrl + entityName + "/delete/" + field + "/" + value, { headers: this.authenticationHeaders, });
    }

    public deleteAllEntities<T>(entityName: string): Observable<any> {
        return this.http.delete<T[]>(this.apiUrl + entityName + "/deleteAll/", { headers: this.authenticationHeaders, });
    }

    public delete<T>(entityName: string): Observable<any> {
        return this.http.delete<T[]>(this.apiUrl + entityName + entityName, { headers: this.authenticationHeaders, });
    }

    public deleteWithBody<T>(entityName: string, entityId: {}){
      return this.http.request<T[]>('delete', this.apiUrl + entityName, { body: entityId, headers: this.authenticationHeaders});
    }

    public getTrama<T>(id: string): Observable<any> {
        return this.http.get<any>(this.apiTramas + id);
    }

    public deleteQueryParams<T>(entityName: string, params): Observable<any> {
        return this.http.delete<T[]>(this.apiUrl + entityName + "/delete/" + params, { headers: this.authenticationHeaders, });
    }

    public deleteFile<T>(entityName: string, params): Observable<any> {
        return this.http.delete<T[]>(this.apiUrl + entityName + params, { headers: this.authenticationHeaders, });
    }


    public sendEmail<T>(entityName: string, email: any): Observable<any> {
        return this.http.get<any>(this.apiLogin + entityName  + '/' + email);
    }

    public downloadFile<T>(name:string, url:string, type:string):Observable<any>{
      let authenticationHeaders = new HttpHeaders().set('x-access-token', this.getToken());
      return this.http.get(url,{ responseType:'blob',headers: authenticationHeaders});
    }

    public uploadFile(params): Promise<any> {
        const formData = new FormData();
        formData.append('documento', params);
        let authenticationHeaders = new HttpHeaders().set('x-access-token', this.getToken());
        return this.http.post<any>(this.apiUrl + "files/create", formData,{headers: authenticationHeaders}).toPromise()
            .then(response => {
                return response;
            })
        .catch(this.handleError);
    }

    public updateFile(params, idFile): Promise<any> {
        const formData = new FormData();
        formData.append('documento', params);
        let authenticationHeaders = new HttpHeaders().set('x-access-token', this.getToken());
        return this.http.put<any>(this.apiUrl + "files/update/" + idFile, formData, {headers: authenticationHeaders}).toPromise()
            .then(response => {
                return response;
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error);
    }

    public uploadFirmware(url: string, formData){
      let authenticationHeaders = new HttpHeaders().set('x-access-token', this.getToken());
      return this.http.post<any>(this.apiUrl+url,formData, {
        headers: authenticationHeaders
      });
    }

    public sendTrama(url:string, data:string): Observable<any>{

        this.authenticationHeaders.set('Access-Control-Allow-Origin: *')

        return this.http.post<any>(`${environment.API_TILE}${url}`, data,  { headers: this.authenticationHeaders , })

    }

     public getItemLocation(url:string): Observable<any>{

        this.authenticationHeaders.set('Access-Control-Allow-Origin: *')

        return this.http.get<any>(`${environment.API_TILE}${url}`,  { headers: this.authenticationHeaders , })

    }

     public getPersonLocation(url:string): Observable<any>{

        this.authenticationHeaders.set('Access-Control-Allow-Origin: *')

        return this.http.get<any>(`${environment.API_TILE}${url}`,  { headers: this.authenticationHeaders , })

    }

    public getItemsInMap(url:string): Observable<any>{

        this.authenticationHeaders.set('Access-Control-Allow-Origin: *')

        return this.http.get<any>(`${environment.API_TILE}${url}`,  { headers: this.authenticationHeaders , })

    }

    public getAuthHeaders(){
        return this.authenticationHeaders
    }

}
